<template>
  <div class="home">
    <div :class="showfff ? 'headerfff' : 'header'">
      <div class="center">
        <div class="left">
          <img
            class="img-logo"
            src="/img/logo.png"
            alt=""
          />
        </div>
        <div class="right">
          <div @click="jumpHome" style='border-bottom: 2px solid #ffa210;
          color: #ffa210;
          box-sizing: border-box;
          cursor: pointer;'>首页</div>
          <div @click="jumpCpfw">产品服务</div>
          <div @click="jumpJjfa">解决方案</div>
          <div @click="jumpGywm">关于我们</div>
        </div>
      </div>
    </div>
    <div>
      <img
        class="img"
        src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/banner.png"
        alt=""
      />
    </div>
    <div class="content">
      <div class="content1">商户经营痛点</div>
      <div class="content2">
        <div class="content2-item">
          <div class="content2-itemm1">
            <div class="button">数据统计难</div>
          </div>
        </div>
        <div class="content2-item">
          <div class="content2-itemm2">
            <div class="button">进售开单难</div>
          </div>
        </div>
        <div class="content2-item">
          <div class="content2-itemm3">
            <div class="button">赊账管理难</div>
          </div>
        </div>
        <div class="content2-item">
          <div class="content2-itemm4">
            <div class="button">商品溯源难</div>
          </div>
        </div>
      </div>
      <div style="height: 60px"></div>
    </div>
    <div class="content3">
      <div class="content3-title">走进大行商</div>
      <div class="content3-text">
        大行商是一款服务于批发市场内商户的商品销售、出入库、发运、收银、赊销及财务对账管理软件。旨在提高商户对商品的管理能力，提升对账效率，简化盘存流程。管理人员进货时填写溯源信息(如货品种类、车型、数量、单价、产地，上传检测证明、产地证证明)，与商品进销绑定，溯源二维码可打印到出售小票上，供买家扫码查阅。
      </div>
      <div class="content3-img">
        <div class="img-item1"></div>
        <div class="img-item2"></div>
        <div class="img-item3"></div>
      </div>
    </div>
    <div class="content4">
      <div class="content4-title">大行商，农批商户的好帮手</div>
      <div class="content4-img">
        <div class="img-item1-div">
          <div class="img-item1"><div class="title">数字经营</div></div>
          <div class="img-item11">
            <div style="display: flex">
              <img
                style="
                  width: 77px;
                  height: 69.7px;
                  margin-right: 30px;
                  margin-top: 20px;
                "
                src="/img/13.png"
                alt=""
              />
              <div>
                <div
                  style="
                    font-size: 24px;
                    font-weight: 600;
                    color: #fff;
                    margin-bottom: 10px;
                  "
                >
                  数字经营
                </div>
                <div
                  style="
                    font-size: 18px;
                    font-weight: 400;
                    color: #fff;
                    line-height: 30px;
                  "
                >
                  进货售货便捷下单，赊欠回款信息存录
                </div>
                <div
                  style="
                    font-size: 18px;
                    font-weight: 400;
                    color: #fff;
                    line-height: 30px;
                  "
                >
                  财务对账清晰了然，经营数据实时掌握
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="img-item1-div">
          <div class="img-item2"><div class="title">定制方案</div></div>
          <div class="img-item11">
            <div style="display: flex">
              <img
                style="
                  width: 77px;
                  height: 73.68px;
                  margin-right: 30px;
                  margin-top: 20px;
                "
                src="/img/14.png"
                alt=""
              />
              <div>
                <div
                  style="
                    font-size: 24px;
                    font-weight: 600;
                    color: #fff;
                    margin-bottom: 10px;
                  "
                >
                  定制方案
                </div>
                <div
                  style="
                    font-size: 18px;
                    font-weight: 400;
                    color: #fff;
                    line-height: 30px;
                  "
                >
                  深挖农批商户需求，定制开发配套方案
                </div>
                <div
                  style="
                    font-size: 18px;
                    font-weight: 400;
                    color: #fff;
                    line-height: 30px;
                  "
                >
                  结合场景多种手段，轻松实现规范管理
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="img-item1-div">
          <div class="img-item3"><div class="title">安全保障</div></div>
          <div class="img-item11">
            <div style="display: flex">
              <img
                style="
                  width: 77px;
                  height: 97.35px;
                  margin-right: 30px;
                  margin-top: 20px;
                "
                src="/img/15.png"
                alt=""
              />
              <div>
                <div
                  style="
                    font-size: 24px;
                    font-weight: 600;
                    color: #fff;
                    margin-bottom: 10px;
                  "
                >
                  安全保障
                </div>
                <div
                  style="
                    font-size: 18px;
                    font-weight: 400;
                    color: #fff;
                    line-height: 30px;
                  "
                >
                  敏感数据加密缓存，确保数据私密安全
                </div>
                <div
                  style="
                    font-size: 18px;
                    font-weight: 400;
                    color: #fff;
                    line-height: 30px;
                  "
                >
                  服务器端安全审计，系统应用权限校验
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="img-item1-div">
          <div class="img-item4"><div class="title">产品服务</div></div>
          <div class="img-item11">
            <div style="display: flex">
              <img
                style="
                  width: 77px;
                  height: 83.84px;
                  margin-right: 30px;
                  margin-top: 20px;
                "
                src="/img/16.png"
                alt=""
              />
              <div>
                <div
                  style="
                    font-size: 24px;
                    font-weight: 600;
                    color: #fff;
                    margin-bottom: 10px;
                  "
                >
                  产品服务
                </div>
                <div
                  style="
                    font-size: 18px;
                    font-weight: 400;
                    color: #fff;
                    line-height: 30px;
                  "
                >
                  售前专人细致讲解，售后培训轻松上手
                </div>
                <div
                  style="
                    font-size: 18px;
                    font-weight: 400;
                    color: #fff;
                    line-height: 30px;
                  "
                >
                  运营服务无微不至，专属团队全程跟踪
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content5">
      <div class="content5-title">项目案例</div>
      <div class="content5-img">
        <div class="img-item">
          <div class="img-item1"></div>
          <div class="name">北京新发地</div>
        </div>
        <div class="img-item">
          <div class="img-item2"></div>
          <div class="name">北水嘉伦水产市场</div>
        </div>
        <div class="img-item">
          <div class="img-item3"></div>
          <div class="name">北菜集团</div>
        </div>
        <div class="img-item">
          <div class="img-item4"></div>
          <div class="name">中渔冷库</div>
        </div>
        <div class="img-item">
          <div class="img-item5"></div>
          <div class="name">中能储运</div>
        </div>
        <div class="img-item">
          <div class="img-item6"></div>
          <div class="name">衢州农商城</div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-left">
        <img
          class="img-logo"
          src="/img/logo.png"
          alt=""
        />
        <div class="text">公司名称：中农城市（北京）科技有限公司</div>
        <div class="text">
          公司地址：北京市朝阳区八里庄路99号住邦2000商务楼3号楼
        </div>
        <div class="text">
          联系方式：赵洪波13261982063&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;杨文奇13651328073&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;张继刚13261140035&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;徐清风13331049610
        </div>
      </div>
      <div class="footer-right">
        <img
          class="img-logo"
          src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/ewm.png"
          alt=""
        />
        <div class="text-small">大行商微信公众号</div>
        <div class="text">扫码关注公众号了解更多</div>
      </div>
    </div>
    <div style='width:80%;margin-left:10%;height:1px;background-color:#dadfe6;margin-bottom:10px'></div>
    <div @click="jump" style='cursor: pointer;margin-left:150px;margin-bottom:20px;font-size:14px'>京ICP备2023001475号-1</div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showfff: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.Scroll, true);
  },
  methods: {
    jump(){
       window.open('https://beian.miit.gov.cn/')
    },
    jumpHome() {
      this.$router.push("/");
    },
    jumpJjfa() {
      this.$router.push("/Jjfa");
    },
    jumpCpfw() {
      this.$router.push("/Cpfw");
    },
    jumpGywm() {
      this.$router.push("/Gywm");
    },
    Scroll() {
      if (window.pageYOffset < 10) {
        this.showfff = false;
      } else {
        this.showfff = true;
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.home {
  .header {
    position: fixed;
    display: flex;
    width: 100%;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.45);
    .center {
      color: #fff;
      width: 100%;
      margin: 0 150px;
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
      .left {
        .img-logo {
          height: 38px;
          width: 107px;
        }
      }
      .right {
        display: flex;
        div {
          height: 60px;
          line-height: 60px;
          margin-left: 50px;
        }
        div:hover {
          box-sizing: border-box;
          border-bottom: 2px solid #ffa210;
          color: #ffa210;
          cursor: pointer;
        }
      }
    }
  }
  .headerfff {
    position: fixed;
    display: flex;
    width: 100%;
    height: 60px;
    background-color: #fff;
    .center {
      color: #000;
      width: 100%;
      margin: 0 150px;
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
      .left {
        .img-logo {
          height: 38px;
          width: 107px;
        }
      }
      .right {
        display: flex;
        div {
          height: 60px;
          line-height: 60px;
          margin-left: 50px;
        }
        div:hover {
          box-sizing: border-box;
          border-bottom: 2px solid #ffa210;
          color: #ffa210;
          cursor: pointer;
        }
      }
    }
  }
  .img {
    width: 100%;
  }
  .content {
    padding: 0 150px;
    .content1 {
      padding: 100px 0;
      display: flex;
      font-size: 42px;
      font-weight: 600;
      color: #000;
      justify-content: center;
    }
    .content2 {
      display: flex;
      flex-wrap: wrap;
      .content2-item {
        padding: 5px;
        width: 50%;
        box-sizing: border-box;
        .content2-itemm1 {
          width: 100%;
          height: 370px;
          background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/spot1.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .content2-itemm2 {
          width: 100%;
          height: 370px;
          background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/spot2.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .content2-itemm3 {
          width: 100%;
          height: 370px;
          background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/spot3.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .content2-itemm4 {
          width: 100%;
          height: 370px;
          background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/spot4.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .button {
          width: 235px;
          height: 75px;
          background: #ffa210;
          border-radius: 63px;
          font-size: 24px;
          font-weight: 600;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .content3 {
    padding: 150px;
    background-color: #f6f7fb;
    .content3-title {
      font-size: 42px;
      font-weight: 600;
      color: #000;
      margin-bottom: 40px;
      text-align: center;
    }
    .content3-text {
      font-size: 18px;
      font-weight: 400;
      color: #333;
      line-height: 30px;
      width: 100%;
      margin: auto;
      text-indent: 42px;
      margin-bottom: 28px;
    }
    .content3-img {
      display: flex;
      justify-content: space-between;
      .img-item1 {
        width: 32.5%;
        background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/enter1.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 330px;
        background-size: 100%;
        transition: background-size 0.3s linear;
      }
      .img-item1:hover {
        background-size: 120%;
        transition: background-size 0.3s linear;
      }
      .img-item2 {
        width: 32.5%;
        background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/enter2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 330px;
        background-size: 100%;
        transition: background-size 0.3s linear;
      }
      .img-item2:hover {
        background-size: 120%;
        transition: background-size 0.3s linear;
      }
      .img-item3 {
        width: 32.5%;
        background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/enter3.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 330px;
        background-size: 100%;
        transition: background-size 0.3s linear;
      }
      .img-item3:hover {
        background-size: 120%;
        transition: background-size 0.3s linear;
      }
    }
  }
  .content4 {
    background-color: #fff;
    padding: 150px;
    .content4-title {
      font-size: 42px;
      font-weight: 600;
      color: #000;
      text-align: center;
      margin-bottom: 100px;
    }
    .content4-img {
      display: flex;
      flex-wrap: wrap;
      .img-item1-div {
        width: 49%;
        margin: 0.5%;
        height: 210px;
        .img-item1 {
          width: 100%;
          background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/num1.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          height: 210px;
          .title {
            font-size: 32px;
            font-weight: 600;
            color: #ffa210;
            margin-left: 132px;
            line-height: 210px;
          }
        }
        .img-item2 {
          width: 100%;
          background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/num2.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          height: 210px;
          .title {
            font-size: 32px;
            font-weight: 600;
            color: #ffa210;
            margin-left: 132px;
            line-height: 210px;
          }
        }
        .img-item3 {
          width: 100%;
          background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/num3.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          height: 210px;
          .title {
            font-size: 32px;
            font-weight: 600;
            color: #ffa210;
            margin-left: 132px;
            line-height: 210px;
          }
        }
        .img-item4 {
          width: 100%;
          background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/num4.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          height: 210px;
          .title {
            font-size: 32px;
            font-weight: 600;
            color: #ffa210;
            margin-left: 132px;
            line-height: 210px;
          }
        }
        .img-item11 {
          display: none;
        }
      }
      .img-item1-div:hover {
        .img-item1 {
          display: none;
        }
        .img-item2 {
          display: none;
        }
        .img-item3 {
          display: none;
        }
        .img-item4 {
          display: none;
        }
        .img-item11 {
          display: block;
          background: #ffa210;
          width: 100%;
          height: 210px;
          color: #fff;
          padding: 50px;
          box-sizing: border-box;
        }
      }
    }
  }
  .content5 {
    padding: 150px;
    background-color: #f6f7fb;
    .content5-title {
      font-size: 42px;
      font-weight: 600;
      color: #000;
      margin-bottom: 40px;
      text-align: center;
    }
    .content5-img {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .name {
        font-size: 18px;
        font-weight: 400;
        color: #000;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 60px;
      }
      .img-item {
        width: 32.5%;
      }
      .img-item1 {
        background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/case1.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 239px;
        background-size: 100%;
        transition: background-size 0.3s linear;
      }
      .img-item1:hover {
        background-size: 120%;
        transition: background-size 0.3s linear;
      }
      .img-item2 {
        background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/case2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 239px;
        background-size: 100%;
        transition: background-size 0.3s linear;
      }
      .img-item2:hover {
        background-size: 120%;
        transition: background-size 0.3s linear;
      }
      .img-item3 {
        background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/case3.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 239px;
        background-size: 100%;
        transition: background-size 0.3s linear;
      }
      .img-item3:hover {
        background-size: 120%;
        transition: background-size 0.3s linear;
      }
      .img-item4 {
        background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/case4.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 239px;
        background-size: 100%;
        transition: background-size 0.3s linear;
      }
      .img-item4:hover {
        background-size: 120%;
        transition: background-size 0.3s linear;
      }
      .img-item5 {
        background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/case5.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 239px;
        background-size: 100%;
        transition: background-size 0.3s linear;
      }
      .img-item5:hover {
        background-size: 120%;
        transition: background-size 0.3s linear;
      }
      .img-item6 {
        background-image: url("https://gwimg.zhongnongpi.cn/dxs-gw/case1/case6.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 239px;
        background-size: 100%;
        transition: background-size 0.3s linear;
      }
      .img-item6:hover {
        background-size: 120%;
        transition: background-size 0.3s linear;
      }
    }
  }
  .footer {
    height: 206px;
    width: 100%;
    padding: 10px 150px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .footer-left {
      .img-logo {
        width: 158px;
        height: 57px;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        margin-top: 20px;
      }
    }
    .footer-right {
      .img-logo {
        width: 120px;
        height: 120px;
      }
      .text-small {
        font-size: 12px;
        font-weight: 400;
        color: #333;
        margin-top: 5px;
        margin-left: 12px;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        margin-top: 15px;
      }
    }
  }
}
</style>