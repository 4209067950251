<template>
  <div class="gywm">
    <div :class="showfff ? 'headerfff' : 'header'">
      <div class="center">
        <div class="left">
          <img
            class="img-logo"
            src="/img/logo.png"
            alt=""
          />
        </div>
        <div class="right">
          <div @click="jumpHome">首页</div>
          <div @click="jumpCpfw">产品服务</div>
          <div @click="jumpJjfa">解决方案</div>
          <div @click="jumpGywm" style='border-bottom: 2px solid #ffa210;
          color: #ffa210;
          box-sizing: border-box;
          cursor: pointer;'>关于我们</div>
        </div>
      </div>
    </div>
    <div class="bjbj">
      <!-- <img
        class="img"
        src="/img/bjbj.jpg"
        alt=""
      /> -->
      <div class='img-title'>
        <div style="font-size:50px">大行商，商户经营由此变得简单</div>
      </div>
    </div>
    <!-- <div>
      <img
        class="img"
        src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/banner4.png"
        alt=""
      />
    </div> -->
    <div class="content">
      <div class="content1">
        <div class="left">
          <img
            class="img1"
            src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/sug1.png"
            alt=""
          />
          <div class="bottom">
            <img
              class="img2"
              src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/sug2.png"
              alt=""
            />
            <img
              class="img3"
              src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/sug3.png"
              alt=""
            />
          </div>
        </div>
        <div class="right">
          <div class="title">公司介绍</div>
          <div class="text">
                中农城市(北京)科技有限公司经营范围包括技术开发、技术服务、技术咨询、计算机软硬件及辅助设备批发、信息系统集成服务、信息系统运行维护服务等。
          </div>
          <div class="text">
                本公司主要提供农业供应链行业发展的软件研发和信息系统集成服务，运用互联网、物联网和大数据技术打造了“大行商电商系统”、“大行商电子结算系统”、“大行商供应链管理系统”、“中农城市仓储系统”、“中农城市冷链系统”、“农业物联网平台”、“农业溯源系统”和“农批大数据平台”等软硬件系统。
          </div>
          <div class="text">
                软件开发目标打通农业供应链全环节，为农批市场从产地收发、市场运营、再到终端渠道提供技术服务支持。
          </div>
        </div>
      </div>
      <div class="content2">
        <div class="title">发展理念</div>
        <div class="text">
          提高用户工作效率，成为农批人的好帮手
        </div>
        <div class="item-list">
          <img
            class="img"
            src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/about2.png"
            alt=""
          />
          <div class="item-all">
            <div class="item">
              <img
                style="width: 50px; height: 46.77px; margin-top: 50px"
                src="/img/5.png"
                alt=""
              />
              <div class="name">定制开发</div>
            </div>
            <div class="item">
              <img
                style="width: 50px; height: 46.77px; margin-top: 50px"
                src="/img/6.png"
                alt=""
              />
              <div class="name">协同</div>
            </div>
            <div class="item">
              <img
                style="width: 50px; height: 46.77px; margin-top: 50px"
                src="/img/7.png"
                alt=""
              />
              <div class="name">深耕农批</div>
            </div>
            <div class="item">
              <img
                style="width: 50px; height: 46.77px; margin-top: 50px"
                src="/img/8.png"
                alt=""
              />
              <div class="name">简化流程</div>
            </div>
            <div class="item">
              <img
                style="width: 50px; height: 46.77px; margin-top: 50px"
                src="/img/9.png"
                alt=""
              />
              <div class="name">提高效率</div>
            </div>
            <div class="item">
              <img
                style="width: 50px; height: 46.77px; margin-top: 50px"
                src="/img/10.png"
                alt=""
              />
              <div class="name">创新</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="content3">
        <div class="title">企业资质荣誉</div>
        <img
          style="width: 100%; height: 961px"
          src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/about3.png"
          alt=""
        />
      </div> -->
    </div>
    <div class="footer">
      <div class="footer-left">
        <img
          class="img-logo"
          src="/img/logo.png"
          alt=""
        />
        <div class="text">公司名称：中农城市（北京）科技有限公司</div>
        <div class="text">
          公司地址：北京市朝阳区八里庄路99号住邦2000商务楼3号楼
        </div>
        <div class="text">
          联系方式：赵洪波13261982063&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;杨文奇13651328073&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;张继刚13261140035&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;徐清风13331049610
        </div>
      </div>
      <div class="footer-right">
        <img
          class="img-logo"
          src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/ewm.png"
          alt=""
        />
        <div class="text-small">大行商微信公众号</div>
        <div class="text">扫码关注公众号了解更多</div>
      </div>
    </div>
    <div style='width:80%;margin-left:10%;height:1px;background-color:#dadfe6;margin-bottom:10px'></div>
    <div @click="jump" style='cursor: pointer;margin-left:150px;margin-bottom:20px;font-size:14px'>京ICP备2023001475号-1</div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showfff: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.Scroll, true);
  },
  methods: {
    jump(){
       window.open('https://beian.miit.gov.cn/')
    },
    jumpHome() {
      this.$router.push("/");
    },
    jumpJjfa() {
      this.$router.push("/Jjfa");
    },
    jumpCpfw() {
      this.$router.push("/Cpfw");
    },
    jumpGywm() {
      this.$router.push("/Gywm");
    },
    Scroll() {
      if (window.pageYOffset == 0) {
        this.showfff = false;
      } else {
        this.showfff = true;
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.gywm {
  .bjbj{
    width:100%;
    height: 660px;
    // padding-bottom: 45%;
    background-image: url("../../public/img/bjbj2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
      .img-title{
        letter-spacing: 3px;
            font-size:60px;
            font-weight: 500;
            color:#fff;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
  }
  .header {
    position: fixed;
    display: flex;
    width: 100%;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.45);
    .center {
      color: #fff;
      width: 100%;
      margin: 0 150px;
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
      .left {
        .img-logo {
          height: 38px;
          width: 107px;
        }
      }
      .right {
        display: flex;
        div {
          height: 60px;
          line-height: 60px;
          margin-left: 50px;
        }
        div:hover {
          border-bottom: 2px solid #ffa210;
          color: #ffa210;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
    }
  }
  .headerfff {
    position: fixed;
    display: flex;
    width: 100%;
    height: 60px;
    background-color: #fff;
    .center {
      color: #000;
      width: 100%;
      margin: 0 150px;
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
      .left {
        .img-logo {
          height: 38px;
          width: 107px;
        }
      }
      .right {
        display: flex;
        div {
          height: 60px;
          line-height: 60px;
          margin-left: 50px;
        }
        div:hover {
          box-sizing: border-box;
          border-bottom: 2px solid #ffa210;
          color: #ffa210;
          cursor: pointer;
        }
      }
    }
  }
  .img {
    width: 100%;
  }
  .content {
    .content1 {
      padding: 100px 150px;
      background: #fff;
      display: flex;
      .left {
        width: 60%;
        height: 751px;
        .img1 {
          width: 100%;
          height: 547px;
        }
        .bottom {
          margin-top: 5px;
          .img2 {
            width: 49%;
            height: 250px;
            margin-right: 2%;
          }
          .img3 {
            width: 49%;
            height: 250px;
          }
        }
      }
      .right {
        width: 38%;
        margin-left: 2%;
        height: 802px;
        background: #ffa210;
        padding: 50px 50px;
        box-sizing: border-box;
        .title {
          font-size: 42px;
          font-weight: 600;
          color: rgb(255, 255, 255);
          margin-bottom: 50px;
          margin-top:50px
        }
        .text {
          font-size: 18px;
          font-weight: 400;
          color: rgb(255, 255, 255);
          line-height: 32px;
          text-indent: 36px;
        }
      }
    }
    .content2 {
      background-color: #f6f7fb;
      padding: 100px 150px;
      .title {
        font-size: 42px;
        font-weight: 600;
        color: rgb(0, 0, 0);
        margin-bottom: 30px;
        text-align: center;
      }
      .text {
        font-size: 24px;
        font-weight: 400;
        color: rgb(0, 0, 0);
        margin-bottom: 110px;
        text-align: center;
      }
      .item-list {
        display: flex;
        .img {
          height: 444px;
          width: 268px;
        }
        .item-all {
          width: calc(100% - 268px);
          height: 444px;
          display: flex;
          flex-wrap: wrap;
          .item {
            width: 32%;
            height: 212px;
            background: #fff;
            margin-left: 8px;
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .name {
              font-size: 24px;
              font-weight: 600;
              color: rgb(53, 53, 53);
              text-align: center;
              margin-top: 30px;
            }
          }
          .item:hover {
            border: #ffa210 2px solid;
            box-sizing: border-box;
          }
        }
      }
    }
    .content3 {
      padding: 60px 150px;
      background: #fff;
      .title {
        font-size: 42px;
        font-weight: 600;
        color: #000;
        text-align: center;
        margin-bottom: 81px;
      }
    }
  }
  .footer {
    height: 206px;
    width: 100%;
    padding: 10px 150px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .footer-left {
      .img-logo {
        width: 158px;
        height: 57px;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        margin-top: 20px;
      }
    }
    .footer-right {
      .img-logo {
        width: 120px;
        height: 120px;
      }
      .text-small {
        font-size: 12px;
        font-weight: 400;
        color: #333;
        margin-top: 5px;
        margin-left: 12px;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        margin-top: 15px;
      }
    }
  }
}
</style>