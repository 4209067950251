<template>
  <div class="cpfw">
    <div :class="showfff ? 'headerfff' : 'header'">
      <div class="center">
        <div class="left">
          <img
            class="img-logo"
            src="/img/logo.png"
            alt=""
          />
        </div>
        <div class="right">
          <div @click="jumpHome">首页</div>
          <div @click="jumpCpfw" style='border-bottom: 2px solid #ffa210;
          color: #ffa210;
          box-sizing: border-box;
          cursor: pointer;'>产品服务</div>
          <div @click="jumpJjfa">解决方案</div>
          <div @click="jumpGywm">关于我们</div>
        </div>
      </div>
    </div>
    <div class="bjbj">
      <!-- <img
        class="img"
        src="/img/bjbj.jpg"
        alt=""
      /> -->
      <div class='img-title'>
        <div>大行商</div>
        <div style="font-size:46px">帮您解决繁琐记账难题，真正实现降本增效</div>
      </div>
    </div>
    <div class="content">
      <div class="content1">
        <div class="title">功能介绍</div>
        <div class="text">轻松实现档口数字化经营管理</div>
        <div class="item-list">
          <div class="item">
            <div style="width: 50%; height: 407px">
              <div class="left">
                <div class="top">掌上开单</div>
                <div class="top2">Print documentsonline</div>
                <div class="line"></div>
                <div class="bottom">进货售货掌上开单</div>
                <div class="bottom">抛弃传统纸质单据</div>
                <div
                  class="arror-img"
                  style="width: 27px; height: 12px; margin-top: 100px"
                  alt=""
                />
              </div>
            </div>
            <img
              class="img"
              src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/use1.png"
              alt=""
            />
          </div>
          <div class="item" style="margin-left: 0.5%">
            <div style="width: 50%; height: 407px">
              <div class="left">
                <div class="top">款项记录</div>
                <div class="top2">Payment record</div>
                <div class="line"></div>
                <div class="bottom">赊欠结清自动区分</div>
                <div class="bottom">付款回款及时记录</div>
                <div
                  class="arror-img"
                  style="width: 27px; height: 12px; margin-top: 100px"
                  alt=""
                />
              </div>
            </div>
            <img
              class="img"
              src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/use2.png"
              alt=""
            />
          </div>
          <div class="item" style="margin-top: 8px">
            <div style="width: 50%; height: 407px">
              <div class="left">
                <div class="top">库存管理</div>
                <div class="top2">Inventory management</div>
                <div class="line"></div>
                <div class="bottom">商品库存规范管理</div>
                <div class="bottom">盘点流程简化高效</div>
                <div
                  class="arror-img"
                  style="width: 27px; height: 12px; margin-top: 100px"
                  alt=""
                />
              </div>
            </div>
            <img
              class="img"
              src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/use3.png"
              alt=""
            />
          </div>
          <div class="item" style="margin-top: 8px; margin-left: 0.5%">
            <div style="width: 50%; height: 407px">
              <div class="left">
                <div class="top">经营统计</div>
                <div class="top2">Business data</div>
                <div class="line"></div>
                <div class="bottom">交易数据实时监测</div>
                <div class="bottom">关键信息首页播放</div>
                <div
                  class="arror-img"
                  style="width: 27px; height: 12px; margin-top: 100px"
                  alt=""
                />
              </div>
            </div>
            <img
              class="img"
              src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/use4.png"
              alt=""
            />
          </div>
          <div class="item" style="margin-top: 8px">
            <div style="width: 50%; height: 407px">
              <div class="left">
                <div class="top">商品溯源</div>
                <div class="top2">Commodity traceability</div>
                <div class="line"></div>
                <div class="bottom">商品信息规范公示</div>
                <div class="bottom">扫码查看溯源信息</div>
                <div
                  class="arror-img"
                  style="width: 27px; height: 12px; margin-top: 100px"
                  alt=""
                />
              </div>
            </div>
            <img
              class="img"
              src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/use5.png"
              alt=""
            />
          </div>
          <div class="item" style="margin-top: 8px; margin-left: 0.5%">
            <div style="width: 50%; height: 407px">
              <div class="left">
                <div class="top">上下游管理</div>
                <div class="top">Upstream and downstream management</div>
                <div class="line"></div>
                <div class="bottom">上游下游多端整合</div>
                <div class="bottom">级联节点协同合作</div>
                <div
                  class="arror-img"
                  style="width: 27px; height: 12px; margin-top: 70px"
                  alt=""
                />
              </div>
            </div>
            <img
              class="img"
              src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/use6.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="content2">
        <div class="title">大行商全链流程图</div>
        <img
          style="width: 100%"
          src="/img/liucheng.png"
          alt=""
        />
      </div>
      <div class="content3">
        <div class="title">我们的优势</div>
        <div class="item-list">
          <div class="item1" style="margin-left: 1%">
            <!-- <img src="/img/2.png"  alt="" /> -->
            <div
              class="img-logo"
              style="width: 62px; height: 68.95px; margin-top: 40px"
            ></div>
            <div class="content3-title">产品优势</div>
            <div class="text">产品操作简单，快速上手</div>
            <div class="text">切合商户经营场景，解决业务难题</div>
            <div class="text">PDA+小程序模式，随时随地查看档口经营</div>
            <div class="text">降本增效，无纸化办公</div>
          </div>
          <div class="item2" style="margin-left: 1%">
            <!-- <img style="width: 62px; height: 50.09px" src="/img/3.png" alt="" /> -->
            <div
              class="img-logo"
              style="width: 62px; height: 50.09px; margin-top: 40px"
            ></div>
            <div class="content3-title">服务优势</div>
            <div class="text">深挖农批商户需求，定制化开发</div>
            <div class="text">7*24h的售后团队，给您解答使用疑问</div>
            <div class="text">专业的开发团队，提升您的使用体验</div>
            <div class="text">专业的产品团队，为您提供更优方案</div>
          </div>
          <div class="item3" style="margin-left: 1%">
            <!-- <img src="/img/4.png" alt="" /> -->
            <div
              class="img-logo"
              style="width: 62px; height: 73.38px; margin-top: 40px"
            ></div>
            <div class="content3-title">安全优势</div>
            <div class="text">数据加密缓存，建立恢复计划</div>
            <div class="text">应用层层加固，提升系统安全</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-left">
        <img
          class="img-logo"
          src="/img/logo.png"
          alt=""
        />
        <div class="text">公司名称：中农城市（北京）科技有限公司</div>
        <div class="text">
          公司地址：北京市朝阳区八里庄路99号住邦2000商务楼3号楼
        </div>
        <div class="text">
          联系方式：赵洪波13261982063&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;杨文奇13651328073&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;张继刚13261140035&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;徐清风13331049610
        </div>
      </div>
      <div class="footer-right">
        <img
          class="img-logo"
          src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/ewm.png"
          alt=""
        />
        <div class="text-small">大行商微信公众号</div>
        <div class="text">扫码关注公众号了解更多</div>
      </div>
    </div>
    <div style='width:80%;margin-left:10%;height:1px;background-color:#dadfe6;margin-bottom:10px'></div>
    <div @click="jump" style='cursor: pointer;margin-left:150px;margin-bottom:20px;font-size:14px'>京ICP备2023001475号-1</div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showfff: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.Scroll, true);
  },
  methods: {
    jump(){
      window.open('https://beian.miit.gov.cn/')
    },
    jumpHome() {
      this.$router.push("/");
    },
    jumpJjfa() {
      this.$router.push("/Jjfa");
    },
    jumpCpfw() {
      this.$router.push("/Cpfw");
    },
    jumpGywm() {
      this.$router.push("/Gywm");
    },
    Scroll() {
      if (window.pageYOffset == 0) {
        this.showfff = false;
      } else {
        this.showfff = true;
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.cpfw {
  .bjbj{
    width:100%;
    height: 660px;
    // padding-bottom: 45%;
    background-image: url("../../public/img/bjbj.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
      .img-title{
        letter-spacing: 3px;
            font-size:60px;
            font-weight: 500;
            color:#fff;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
  }
  .header {
    position: fixed;
    display: flex;
    width: 100%;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.45);
    .center {
      color: #fff;
      width: 100%;
      margin: 0 150px;
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
      .left {
        .img-logo {
          height: 38px;
          width: 107px;
        }
      }
      .right {
        display: flex;
        div {
          height: 60px;
          line-height: 60px;
          margin-left: 50px;
        }
        div:hover {
          box-sizing: border-box;
          border-bottom: 2px solid #ffa210;
          color: #ffa210;
          cursor: pointer;
        }
      }
    }
  }
  .headerfff {
    position: fixed;
    display: flex;
    width: 100%;
    height: 60px;
    background-color: #fff;
    .center {
      color: #000;
      width: 100%;
      margin: 0 150px;
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
      .left {
        .img-logo {
          height: 38px;
          width: 107px;
        }
      }
      .right {
        display: flex;
        div {
          height: 60px;
          line-height: 60px;
          margin-left: 50px;
        }
        div:hover {
          box-sizing: border-box;
          border-bottom: 2px solid #ffa210;
          color: #ffa210;
          cursor: pointer;
        }
      }
    }
  }
  .img {
    width: 100%;
  }
  .content {
    .content1 {
      padding: 100px 150px;
      background: #f6f7fb;
      .title {
        font-size: 42px;
        font-weight: 600;
        color: #000;
        margin-bottom: 30px;
        text-align: center;
      }
      .text {
        font-size: 24px;
        font-weight: 400;
        color: #000;
        margin-bottom: 80px;
        text-align: center;
      }
      .item-list {
        display: flex;
        flex-wrap: wrap;
        .item {
          width: 49%;
          height: 407px;
          background: #fff;
          color: #353535;
          display: flex;
          .left {
            padding: 50px 25px;
            .top {
              font-size: 26px;
              font-weight: 600;
              margin-bottom: 17px;
            }
            .top2 {
              height: 70px;
              font-size: 26px;
              font-weight: 600;
              margin-bottom: 17px;
            }
            .line {
              width: 100%;
              height: 1px;
              background-color: #ffa210;
              margin-bottom: 20px;
            }
            .bottom {
              font-size: 18px;
              font-weight: 400;
            }
            .arror-img {
              background-image: url("../../public/img/1.png");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
          .left:hover {
            height: 307px;
            background: #ffa210;
            color: #fff;
            padding: 50px 25px;
            .top {
              font-size: 26px;
              font-weight: 600;
              margin-bottom: 17px;
            }
            .top2 {
              height: 70px;
              font-size: 26px;
              font-weight: 600;
              margin-bottom: 17px;
            }
            .line {
              width: 100%;
              height: 1px;
              background-color: #fff;
              margin-bottom: 20px;
            }
            .bottom {
              font-size: 18px;
              font-weight: 400;
            }
            .arror-img {
              background-image: url("../../public/img/111.png");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
          .img {
            width: 50%;
            height: 407px;
          }
        }
      }
    }
    .content2 {
      padding: 60px 150px;
      background: #fff;
      .title {
        font-size: 42px;
        font-weight: 600;
        color: #000;
        text-align: center;
        margin-bottom: 81px;
      }
    }
    .content3 {
      padding: 60px 150px;
      background: #f6f7fb;
      .title {
        font-size: 42px;
        font-weight: 600;
        color: #000;
        text-align: center;
        margin-bottom: 81px;
      }
      .item-list {
        display: flex;
        .item1 {
          width: 33%;
          height: 377px;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #333;
          .content3-title {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 15px;
            text-align: center;
            margin-top: 15px;
          }
          .text {
            font-size: 14px;
            font-weight: 400;
            line-height: 40px;
            text-align: center;
          }
          .img-logo {
            background-image: url("../../public/img/2.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .item1:hover {
          background-color: #ffa210;
          color: #fff;
          width: 33%;
          height: 377px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .content3-title {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 15px;
            text-align: center;
            margin-top: 15px;
          }
          .text {
            font-size: 14px;
            font-weight: 400;
            line-height: 40px;
            text-align: center;
          }
          .img-logo {
            background-image: url("../../public/img/22.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .item2 {
          width: 33%;
          height: 377px;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #333;
          .content3-title {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 15px;
            text-align: center;
            margin-top: 15px;
          }
          .text {
            font-size: 14px;
            font-weight: 400;
            line-height: 40px;
            text-align: center;
          }
          .img-logo {
            background-image: url("../../public/img/3.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .item2:hover {
          background-color: #ffa210;
          color: #fff;
          width: 33%;
          height: 377px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .content3-title {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 15px;
            text-align: center;
            margin-top: 15px;
          }
          .text {
            font-size: 14px;
            font-weight: 400;
            line-height: 40px;
            text-align: center;
          }
          .img-logo {
            background-image: url("../../public/img/33.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .item3 {
          width: 33%;
          height: 377px;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #333;
          .content3-title {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 15px;
            text-align: center;
            margin-top: 15px;
          }
          .text {
            font-size: 14px;
            font-weight: 400;
            line-height: 40px;
            text-align: center;
          }
          .img-logo {
            background-image: url("../../public/img/4.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .item3:hover {
          background-color: #ffa210;
          color: #fff;
          width: 33%;
          height: 377px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .content3-title {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 15px;
            text-align: center;
            margin-top: 15px;
          }
          .text {
            font-size: 14px;
            font-weight: 400;
            line-height: 40px;
            text-align: center;
          }
          .img-logo {
            background-image: url("../../public/img/44.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }
  .footer {
    height: 206px;
    width: 100%;
    padding: 10px 150px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .footer-left {
      .img-logo {
        width: 158px;
        height: 57px;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        margin-top: 20px;
      }
    }
    .footer-right {
      .img-logo {
        width: 120px;
        height: 120px;
      }
      .text-small {
        font-size: 12px;
        font-weight: 400;
        color: #333;
        margin-top: 5px;
        margin-left: 12px;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        margin-top: 15px;
      }
    }
  }
}
</style>