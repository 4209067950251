<template>
  <div class="jjfa">
    <div :class="showfff ? 'headerfff' : 'header'">
      <div class="center">
        <div class="left">
          <img
            class="img-logo"
            src="/img/logo.png"
            alt=""
          />
        </div>
        <div class="right">
          <div @click="jumpHome">首页</div>
          <div @click="jumpCpfw">产品服务</div>
          <div @click="jumpJjfa" style='border-bottom: 2px solid #ffa210;
          color: #ffa210;
          box-sizing: border-box;
          cursor: pointer;'>解决方案</div>
          <div @click="jumpGywm">关于我们</div>
        </div>
      </div>
    </div>
    <div>
      <img
        class="img"
        src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/banner3.png"
        alt=""
      />
    </div>
    <div class="content">
      <div class="content1">
        <div class="item">
          <div class="title">传统农批市场现状</div>
          <div class="arrow"></div>
        </div>
        <div class="item">
          <div class="title">大行商带来的改变</div>
          <div class="arrow"></div>
        </div>
      </div>
      <div class="content2">
        <div class="item">
          <img
            src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/solve1.png"
            alt=""
          />
          <div class="title">纸质开单</div>
          <div class="text">
            开单全部需要手工填写，保存为纸质单据。手工填写工作量巨大，且容易出现错填、漏填的情况。纸质单据存在整理困难，容易丢失损坏的风险。
          </div>
        </div>
        <div class="item" style="margin-left: 1%">
          <img
            src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/solve2.png"
            alt=""
          />
          <div class="title">电子开单</div>
          <div class="text">
            系统开单，几秒即可完成。后期对账时便捷查阅，极大提高了工作效率。
          </div>
        </div>
        <div class="item" style="margin-top: 15px">
          <img
            src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/solve3.png"
            alt=""
          />
          <div class="title">买家/货主等信息管理</div>
          <div class="text">
            买家与货主等信息收集不全/不准，每次开单时都需重复书写，并且存在相关信息难以交接的问题。
          </div>
        </div>
        <div class="item" style="margin-top: 15px; margin-left: 1%">
          <img
            src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/solve4.png"
            alt=""
          />
          <div class="title">电子信息管理</div>
          <div class="text">
            买家与货主等信息录到系统内，自动形成信息库，开单时直接选择，无需再次填写。相关开单数据永久记录货主/买家信息。
          </div>
        </div>
        <div class="item" style="margin-top: 15px">
          <img
            src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/solve5.png"
            alt=""
          />
          <div class="title">库存管理</div>
          <div class="text">
            传统库存记录需翻阅所有相关纸质单据，若存在单据不准或丢失的情况，则导致库存盘点极为混乱，且人工核算容易出现计算错误的问题。
          </div>
        </div>
        <div class="item" style="margin-top: 15px; margin-left: 1%">
          <img
            src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/solve6.png"
            alt=""
          />
          <div class="title">库存管理</div>
          <div class="text">
            库存与订单挂钩，进货售货后自动更新库存，可于库存界面直接查看剩余库存，库存核对与盘点不再需要统计每个人手中的订单数据，提高盘存效率。
          </div>
        </div>
        <div class="item" style="margin-top: 15px">
          <img
            src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/solve7.png"
            alt=""
          />
          <div class="title">经营数据统计</div>
          <div class="text">
            档主查看某阶段经营数据时，需翻找出该阶段所有纸质单据，再通过手工计算得出该阶段档口的经营情况，效率低下，数据获取延迟性较高。
          </div>
        </div>
        <div class="item" style="margin-top: 15px; margin-left: 1%">
          <img
            src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/solve8.png"
            alt=""
          />
          <div class="title">经营数据统计</div>
          <div class="text">
            进货售货后根据订单金额，自动汇入至相关数据报表，可根据时间、状态等条件进行归类统计，实时获取实时更新，档主可随时随地查看档口任何阶段经营情况。
          </div>
        </div>
        <div class="item" style="margin-top: 15px">
          <img
            src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/solve9.png"
            alt=""
          />
          <div class="title">回款/付款管理</div>
          <div class="text">
            线下存在大量赊账与付款项，且金额庞大单据庞杂，每次回款与付款时都需更新手工记录。
          </div>
        </div>
        <div class="item" style="margin-top: 15px; margin-left: 1%">
          <img
            src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/solve10.png"
            alt=""
          />
          <div class="title">回款/付款管理</div>
          <div class="text">
            回款与付款信息存录到系统内，系统自动得出剩余赊欠与待付款值，赊欠详情一目了然。
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-left">
        <img
          class="img-logo"
          src="/img/logo.png"
          alt=""
        />
        <div class="text">公司名称：中农城市（北京）科技有限公司</div>
        <div class="text">
          公司地址：北京市朝阳区八里庄路99号住邦2000商务楼3号楼
        </div>
        <div class="text">
          联系方式：赵洪波13261982063&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;杨文奇13651328073&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;张继刚13261140035&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;徐清风13331049610
        </div>
      </div>
      <div class="footer-right">
        <img
          class="img-logo"
          src="https://gwimg.zhongnongpi.cn/dxs-gw/case1/ewm.png"
          alt=""
        />
        <div class="text-small">大行商微信公众号</div>
        <div class="text">扫码关注公众号了解更多</div>
      </div>
    </div>
    <div style='width:80%;margin-left:10%;height:1px;background-color:#dadfe6;margin-bottom:10px'></div>
    <div @click="jump" style='cursor: pointer;margin-left:150px;margin-bottom:20px;font-size:14px'>京ICP备2023001475号-1</div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showfff: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.Scroll, true);
  },
  methods: {
    jump(){
       window.open('https://beian.miit.gov.cn/')
    },
    jumpHome() {
      this.$router.push("/");
    },
    jumpJjfa() {
      this.$router.push("/Jjfa");
    },
    jumpCpfw() {
      this.$router.push("/Cpfw");
    },
    jumpGywm() {
      this.$router.push("/Gywm");
    },
    Scroll() {
      if (window.pageYOffset == 0) {
        this.showfff = false;
      } else {
        this.showfff = true;
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.jjfa {
  .header {
    position: fixed;
    display: flex;
    width: 100%;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.45);
    .center {
      color: #fff;
      width: 100%;
      margin: 0 150px;
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
      .left {
        .img-logo {
          height: 38px;
          width: 107px;
        }
      }
      .right {
        display: flex;
        div {
          height: 60px;
          line-height: 60px;
          margin-left: 50px;
        }
        div:hover {
          box-sizing: border-box;
          border-bottom: 2px solid #ffa210;
          color: #ffa210;
          cursor: pointer;
        }
      }
    }
  }
  .headerfff {
    position: fixed;
    display: flex;
    width: 100%;
    height: 60px;
    background-color: #fff;
    .center {
      color: #000;
      width: 100%;
      margin: 0 150px;
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
      .left {
        .img-logo {
          height: 38px;
          width: 107px;
        }
      }
      .right {
        display: flex;
        div {
          height: 60px;
          line-height: 60px;
          margin-left: 50px;
        }
        div:hover {
          box-sizing: border-box;
          border-bottom: 2px solid #ffa210;
          color: #ffa210;
          cursor: pointer;
        }
      }
    }
  }
  .img {
    width: 100%;
  }
  .content {
    padding: 60px 150px;
    background: #f6f7fb;
    .content1 {
      margin-bottom: 50px;
      display: flex;
      .item {
        width: 50%;
        .title {
          font-size: 42px;
          font-weight: 600;
          color: #000;
          margin-bottom: 25px;
          text-align: center;
        }
        .arrow {
          border-left: 30px solid transparent;
          border-right: 30px solid transparent;
          border-top: 40px solid #ffa210;
          width: 0;
          height: 0;
          margin: auto;
        }
      }
    }
    .content2 {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 49.5%;
        height: 594px;
        background-color: #fff;
        img {
          height: 400px;
          width: 100%;
        }
        .title {
          margin-top: 30px;
          text-align: center;
          font-size: 24px;
          font-weight: 600;
          color: #333;
          margin-bottom: 20px;
        }
        .text {
          margin: 0 30px;
          font-size: 18px;
          font-weight: 400;
          color: #333;
          line-height: 30px;
          text-indent: 38px;
        }
      }
    }
  }
  .footer {
    height: 206px;
    width: 100%;
    padding: 10px 150px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .footer-left {
      .img-logo {
        width: 158px;
        height: 57px;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        margin-top: 20px;
      }
    }
    .footer-right {
      .img-logo {
        width: 120px;
        height: 120px;
      }
      .text-small {
        font-size: 12px;
        font-weight: 400;
        color: #333;
        margin-top: 5px;
        margin-left: 12px;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        margin-top: 15px;
      }
    }
  }
}
</style>