import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/home.vue'
import Jjfa from '../components/jjfa.vue'
import Cpfw from '../components/cpfw.vue'
import Gywm from '../components/gywm.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},{
    path: '/Jjfa',
    name: 'Jjfa',
    component: Jjfa
},{
    path: '/Cpfw',
    name: 'Cpfw',
    component: Cpfw
},{
    path: '/Gywm',
    name: 'Gywm',
    component: Gywm
}]

const router = new VueRouter({
    routes
})

export default router