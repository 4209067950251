<template>
  <div>
    <div style="position: fixed; bottom: 200px; right: 15px">
      <el-popover
        title="电话咨询"
        placement="right"
        width="150"
        trigger="click"
      >
        <div>
          <div>赵洪波：<span style="color: #ffa210">13261982063</span></div>
          <div>杨文奇：<span style="color: #ffa210">13651328073</span></div>
          <div>张继刚：<span style="color: #ffa210">13261140035</span></div>
          <div>徐清风：<span style="color: #ffa210">13331049610</span></div>
        </div>
        <div slot="reference">
          <img
            style="width: 92px; height: 94px"
            src="/img/phone.png"
            alt=""
          />
          <div
            style="
              width: 80px;
              height: 77px;
              background: #ffa412;
              box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.22);
              margin: auto;
              margin-top: 3px;
              padding-top: 15px;
              box-sizing: border-box;
              color: #fff;
            "
          >
            <img
              style="width: 29px; height: 27px; margin-left: 25px"
              src="/img/11.png"
              alt=""
            />
            <div style="margin-left: 10px; font-size: 14px">在线联系</div>
          </div>
        </div>
      </el-popover>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
.el-tabs__nav-wrap {
}
</style>
